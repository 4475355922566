import { ApiService } from '@services/ApiService';
import moment from 'moment';

interface IGetTransactionsParams {
  from: Date;
  to: Date;
  page: number;
  perPage?: number;
  successfulOnly?: boolean;
}

export const TransactionsService = {
  async getLastMonthSuccessFulTransactions() {
    const parameters = {
      from: moment.utc().subtract(1, 'month').toDate(),
      to: moment.utc().toDate(),
      successfulOnly: true,
    };
    const perPage = 50;
    const totalTransaction = await this.countTransaction(parameters);
    const pages = Math.ceil(totalTransaction / perPage);
    let currentPage = 1;
    let result: Transaction[];
    const transactions: Transaction[] = [];
    do {
      result = await this.getTransactions({
        ...parameters,
        page: currentPage,
      });
      transactions.push(...result);
      currentPage += 1;
    } while (currentPage < pages);
    return transactions;
  },

  async countTransaction(params: Omit<IGetTransactionsParams, 'page' | 'perPage'>): Promise<number> {
    const res = await ApiService.call('POST', '/transactions/count');

    return res.data;
  },

  async getTransactions(params: IGetTransactionsParams): Promise<Transaction[]> {
    const res = await ApiService.call('POST', '/transactions/list', {
      ...params,
      perPage: params.perPage || 50,
    });

    return res.data;
  },
};

export type Transaction = {
  _id: string;
  amount: number;
  date: Date;
  terminalId: string;
  transactionId: string;
  responseCode: string;
  status: string;
  type: 'CREDIT_CARD' | 'WALLET';
  merchant?: string;
  pos?: string;
};
