import { ApiService } from '@services/ApiService';
import { AxiosResponse } from 'axios';

export const AuthenticationService = {
  async login(email: string, password: string): Promise<AxiosResponse> {
    const res = await ApiService.call('POST', '/authentication/login', { email, password });
    localStorage.setItem('sessionId', res.data._id);
    localStorage.setItem('accessToken', res.data.token);
    localStorage.setItem('userId', res.data.user);
    return res.data;
  },

  async logout(): Promise<void> {
    const sessionId = localStorage.getItem('sessionId');
    try {
      await ApiService.call('DELETE', `/authentication/logout`, { sessionId });
    } finally {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('sessionId');
      localStorage.removeItem('userId');
      window.location.href = '/';
    }
  },

  isLoggedIn(): boolean {
    return Boolean(localStorage.getItem('accessToken'));
  },
};
