import { AuthenticationService } from '@services/AuthenticationService';
import { isApiError } from '@utils/error';
import { Button, Form, Input, Space } from 'antd';
import { FC, useCallback } from 'react';

import './index.scss';

const LoginForm: FC = () => {
  const onFinish = useCallback(async (values: { email: string; password: string }) => {
    try {
      await AuthenticationService.login(values.email, values.password);
    } catch (e: unknown) {
      if (isApiError(e)) {
        alert(e.errorMessage);
      }
    }
    window.location.href = '/';
  }, []);

  return (
    <Form className="login-form" name="login-form" onFinish={onFinish}>
      <span className="login-label">
        <b>Email</b>
      </span>
      <Form.Item className="login-input" name="email" rules={[{ required: true, message: 'Please input your email!' }]}>
        <Input />
      </Form.Item>

      <span className="login-label">
        <b>Password</b>
      </span>
      <Form.Item
        className="login-input"
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password />
      </Form.Item>

      <Space direction="vertical" align="end">
        <Button className="login-submit" type="primary" htmlType="submit">
          <b>Sign in</b>
        </Button>
      </Space>
    </Form>
  );
};

export default LoginForm;
