import cn from 'classnames';
import { FC, HTMLAttributes } from 'react';

import './index.scss';

export interface ISpinnerProps extends HTMLAttributes<HTMLDivElement> {
  variant?: 'white' | 'black';
}

const Spinner: FC<ISpinnerProps> = ({ className, variant = 'white', ...props }) => (
  <div className={cn('spinner', `spinner-${variant}`, className)} {...props}>
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default Spinner;
