import cn from 'classnames';
import { FC, HTMLAttributes, ReactNode } from 'react';

import './index.scss';

export interface IFlexProps extends HTMLAttributes<HTMLDivElement> {
  direction?: 'horizontal' | 'vertical';
  gap?: 'none' | 'xs' | 's' | 'm' | 'l' | 'xl';
  wrap?: boolean;
  justify?: 'start' | 'center' | 'end' | 'space-between' | 'space-around';
  align?: 'start' | 'center' | 'end' | 'space-between' | 'space-around';
  children?: ReactNode;
}

const Flex: FC<IFlexProps> = ({
  children,
  gap = 'none',
  direction = 'horizontal',
  justify = 'start',
  align = 'start',
  wrap,
  className,
  ...props
}) => (
  <div
    className={cn(
      'flex',
      `flex-${direction}`,
      `flex-justify-${justify}`,
      `flex-align-${align}`,
      `flex-gap-${gap}`,
      { 'flex-wrap': wrap },
      className,
    )}
    {...props}
  >
    {children}
  </div>
);

export default Flex;
