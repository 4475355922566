import cn from 'classnames';
import { FC, HTMLAttributes, ReactNode } from 'react';

import './index.scss';

export interface ITitleProps extends HTMLAttributes<HTMLHeadingElement> {
  theme?: 'normal' | 'grey';
  level?: 1 | 2 | 3 | 4 | 5 | 6 | '1' | '2' | '3' | '4' | '5' | '6';
  children?: ReactNode;
}

const Title: FC<ITitleProps> = ({ children, level = 4, theme = 'normal', className, ...props }) => {
  const Tag: keyof JSX.IntrinsicElements = `h${level}`;

  return (
    <Tag className={cn('title', `title-${level}`, `title-${theme}`, className)} {...props}>
      {children}
    </Tag>
  );
};

export default Title;
