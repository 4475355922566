import logo from '@assets/images/logo_white.svg';
import Flex from '@components/atoms/Flex';
import { HOME_ROUTE } from '@routing/routes';
import { Image } from 'antd';
import { FC } from 'react';
import { useNavigate } from 'react-router';

import './index.scss';

const Sidenav: FC = () => {
  const navigate = useNavigate();

  return (
    <Flex className="sidenav py-4" direction="vertical" align="center">
      <Image className="cursor-pointer" src={logo} preview={false} onClick={() => navigate(HOME_ROUTE())} />
    </Flex>
  );
};

export default Sidenav;
