import cn from 'classnames';
import { FC, HTMLAttributes, ReactNode } from 'react';

import './index.scss';

export interface ITextProps extends HTMLAttributes<HTMLSpanElement> {
  theme?: 'normal' | 'grey';
  children?: ReactNode;
}

const Text: FC<ITextProps> = ({ children, theme = 'normal', className, ...props }) => (
  <span className={cn('text', `text-${theme}`, className)} {...props}>
    {children}
  </span>
);

export default Text;
