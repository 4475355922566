import Card, { ICardProps } from '@components/atoms/Card';
import Spinner from '@components/atoms/Spinner';
import Text from '@components/atoms/typography/Text';
import Title from '@components/atoms/typography/Title';
import { ApexOptions } from 'apexcharts';
import cn from 'classnames';
import { FC, ReactElement } from 'react';
import ReactApexChart from 'react-apexcharts';

import './index.scss';

interface IChartCardProps extends ICardProps {
  title: string;
  subtitle?: string;
  actions?: ReactElement;
  loading?: boolean;
  chartOptions: ApexOptions;
  chartSeries: ApexOptions['series'];
}

const ChartCard: FC<IChartCardProps> = ({
  actions,
  className,
  title,
  subtitle,
  chartOptions,
  chartSeries,
  loading = false,
  ...props
}) => (
  <Card className={cn('chart-card', className)} {...props}>
    <div className="flex justify-between">
      <div className="chart-card-titles">
        <Title level="3">{title}</Title>
        {subtitle && <Text theme="grey">{subtitle}</Text>}
      </div>
      {actions && <div className="chart-card-actions">{actions}</div>}
    </div>
    <div className="chart-card-chart-container flex align-center justify-center">
      {loading ? (
        <Spinner variant="black" />
      ) : (
        <ReactApexChart className="flex-1" options={chartOptions} series={chartSeries} height="100%" />
      )}
    </div>
  </Card>
);

export default ChartCard;
