import { DependencyList, useCallback, useEffect, useState } from 'react';

type Await<T> = T extends Promise<infer U> ? U : T;
type PromiseFn<P extends any> = (...args: any) => Promise<P>;

type LoadingData = {
  status: 'LOADING';
  data: null;
};
type SuccessData<T> = {
  status: 'SUCCESS';
  data: T;
};
type ErrorData = {
  status: 'ERROR';
  error: any;
  data: null;
};
export type AsyncData<D> = LoadingData | SuccessData<D> | ErrorData;

const useAsync = <F extends PromiseFn<any>>(promiseFn: F, dependencies?: DependencyList) => {
  const [result, setResult] = useState<LoadingData | SuccessData<Await<ReturnType<F>>> | ErrorData>({
    status: 'LOADING',
    data: null,
  });

  const refresh = useCallback(() => {
    setResult({
      status: 'LOADING',
      data: null,
    });
    promiseFn()
      .then(data => {
        setResult({
          status: 'SUCCESS',
          data,
        });
      })
      .catch(error => {
        setResult({
          status: 'ERROR',
          error,
          data: null,
        });
      });
  }, dependencies || []);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return { refresh, ...result };
};

export default useAsync;
