import backgroundPattern from '@assets/images/wave-bg-white.svg';
import Card, { ICardProps } from '@components/atoms/Card';
import Spinner from '@components/atoms/Spinner';
import Title from '@components/atoms/typography/Title';
import { Icon } from '@mdi/react';
import { Space } from 'antd';
import cn from 'classnames';
import { FC } from 'react';

import './index.scss';

interface IPromoteCardProps extends ICardProps {
  scheme: 'primary' | 'secondary';
  icon: string;
  mainValue: string;
  mainLabel: string;
  derivativeValue?: string;
  derivativeLabel?: string;
  loading?: boolean;
}

const PromoteCard: FC<IPromoteCardProps> = promoteCardProps => {
  const {
    scheme = 'primary',
    icon,
    mainLabel,
    mainValue,
    derivativeLabel,
    derivativeValue,
    className,
    ...props
  } = promoteCardProps;

  return (
    <Card className={cn('promote-card', `promote-card-${scheme}`, className)} {...props}>
      <img className="promote-card-pattern" src={backgroundPattern} />
      <div className="promote-card-container">
        <Icon path={icon} size={2} />
        <PrimaryData {...promoteCardProps} />
        <SecondaryData {...promoteCardProps} />
      </div>
    </Card>
  );
};

function PrimaryData({ mainValue, mainLabel, loading }: IPromoteCardProps) {
  return (
    <Space className="promote-card-main" direction="horizontal" align="center">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Title className="promote-card-main-label">{mainValue}</Title>
          <Title level="5">{mainLabel}</Title>
        </>
      )}
    </Space>
  );
}

function SecondaryData({ derivativeValue, derivativeLabel, loading }: IPromoteCardProps) {
  return derivativeLabel ? (
    <div className="promote-card-derivative">
      {loading ? (
        <>
          <Title level="4" style={{ opacity: 0 }}>
            -
          </Title>
          <Title level="5" style={{ opacity: 0 }}>
            -
          </Title>
        </>
      ) : (
        <>
          <Title level="4">{derivativeValue}</Title>
          <Title level="5" theme="grey">
            {derivativeLabel}
          </Title>
        </>
      )}
    </div>
  ) : (
    <></>
  );
}

export default PromoteCard;
