import { isISOString } from '@utils/date';
import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';

export const ApiService = {
  call(method: Method, path: string, body?: Record<string, any>): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
      const url = process.env.REACT_APP_API_URL + path;

      const axiosOptions: AxiosRequestConfig = {
        method,
        url,
        data: body ?? {},
        headers: buildHeaders(),
      };

      axios(axiosOptions)
        .then(result => {
          result.data = reconstructDates(result.data);
          resolve(result);
        })
        .catch(e => {
          if (e.response?.status === 401 && !window.location.pathname.startsWith('/login')) {
            window.location.href = '/login';
          } else {
            const error = e.response.data.message?.split(':');

            return reject({ ...e, errorCode: error[0], errorMessage: error[1] });
          }
        });
    });
  },
};

const buildHeaders = (): AxiosRequestConfig['headers'] => {
  const accessToken = localStorage.getItem('accessToken');

  const headers: AxiosRequestConfig['headers'] = {};

  if (accessToken) {
    headers.authorization = `Bearer ${accessToken}`;
  }

  return headers;
};

function reconstructDates<T extends Record<string, any>>(objectOrArray: T): T {
  const recursiveTransform = (obj: Record<string, any>): any => {
    if (!obj) return obj;

    return Object.keys(obj).reduce((prev: typeof obj, next: string) => {
      if (Array.isArray(obj[next])) {
        prev[next] = obj[next].map(recursiveTransform);
      } else if (isISOString(obj[next])) {
        prev[next] = new Date(obj[next]);
      } else if (typeof obj[next] === 'object') {
        prev[next] = recursiveTransform(obj[next]);
      } else {
        prev[next] = obj[next];
      }
      return prev;
    }, {});
  };

  return Array.isArray(objectOrArray) ? objectOrArray.map(recursiveTransform) : recursiveTransform(objectOrArray);
}
