import { Button, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ApexOptions } from 'apexcharts';
import { FC } from 'react';
import { CSVLink } from 'react-csv';
import Card from '@components/atoms/Card';
import Flex from '@components/atoms/Flex';
import ChartCard from '@components/molecules/ChartCard';
import PromoteCard from '@components/molecules/PromoteCard';
import { mdiBank, mdiCashMultiple } from '@mdi/js';
import useGetHomeContext, { HomeContext } from '@pages/Home/useGetHomeContext';
import { Transaction } from '@services/TransactionsService';

import { serializeTransactionsForCsv } from './serializeTransactionsForCsv';

const Home: FC = () => {
  const { lastMonthSuccessfulTransactionsAsync, lastFiftyTransactionsAsync } = useGetHomeContext();

  return (
    <div id="home-page">
      <Flex gap="xs" wrap>
        <TotalTransactionsPromoteCard {...lastMonthSuccessfulTransactionsAsync} />
        <TotalAmountPromoteCard {...lastMonthSuccessfulTransactionsAsync} />
        <AmountPerDayChart {...lastMonthSuccessfulTransactionsAsync} />
      </Flex>
      <Flex className="mt-4">
        <TransactionsTable {...lastFiftyTransactionsAsync} />
      </Flex>
    </div>
  );
};

function TotalTransactionsPromoteCard(lastMonthData: HomeContext['lastMonthSuccessfulTransactionsAsync']) {
  const loading = lastMonthData.status === 'LOADING';
  if (lastMonthData.status === 'ERROR') return <div>ERROR</div>;
  return (
    <PromoteCard
      loading={Boolean(loading)}
      icon={mdiBank}
      mainLabel="Transactions last month"
      mainValue={`${lastMonthData.data?.transactions.length}`}
      derivativeLabel="Average per day"
      derivativeValue={`${(Number(lastMonthData.data?.transactions.length || 0) / 30).toFixed(0)}`}
      scheme="primary"
      width="s"
    />
  );
}

function TotalAmountPromoteCard(lastMonthData: HomeContext['lastMonthSuccessfulTransactionsAsync']) {
  const loading = lastMonthData.status === 'LOADING';
  if (lastMonthData.status === 'ERROR') return <div>ERROR</div>;
  return (
    <PromoteCard
      loading={Boolean(loading)}
      icon={mdiCashMultiple}
      mainLabel="total in FCFA"
      mainValue={`${Number(lastMonthData.data?.total).toLocaleString()}`}
      derivativeLabel="Average per day"
      derivativeValue={`${(Number(lastMonthData.data?.total || 0) / 30).toFixed(0)}`}
      scheme="secondary"
      width="s"
    />
  );
}

function AmountPerDayChart(lastMonthData: HomeContext['lastMonthSuccessfulTransactionsAsync']) {
  const loading = lastMonthData.status === 'LOADING';
  if (lastMonthData.status === 'ERROR') return <div>ERROR</div>;
  const options: ApexOptions = {
    chart: {
      height: 350,
      type: 'area',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: 'datetime',
      categories: Object.keys(lastMonthData.data?.totalByDate || {}),
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm',
      },
    },
    grid: {
      borderColor: 'rgba(17, 17, 17, 0.15)',
      strokeDashArray: 5,
    },
    colors: ['#753D90'],
  };

  const series = [
    {
      name: 'Amount',
      data: Object.values(lastMonthData.data?.totalByDate || {}),
    },
  ];

  return (
    <ChartCard
      actions={
        <CSVLink data={serializeTransactionsForCsv(lastMonthData.data?.transactions || [])}>
          <Button type="primary">Download CSV</Button>
        </CSVLink>
      }
      className="flex-1"
      loading={loading}
      chartOptions={options}
      chartSeries={series}
      title="Transactions"
      subtitle="Last 30 days"
      width="l"
    />
  );
}

const statusStringToColour = function (str: Transaction['status']) {
  switch (str) {
    case 'Approved':
      return 'green';
    case 'Error':
      return 'volcano';
    case 'Pending':
      return 'yellow';
  }
};

const typeToColour = function (str: Transaction['type']) {
  switch (str) {
    case 'WALLET':
      return 'cyan';
    case 'CREDIT_CARD':
      return 'purple';
  }
};

function TransactionsTable(lastFiftyTransactions: HomeContext['lastFiftyTransactionsAsync']) {
  if (lastFiftyTransactions.status !== 'SUCCESS') return <div />;
  const columns: ColumnsType<Transaction> = [
    {
      title: 'Id',
      dataIndex: '_id',
      key: '_id',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Tid',
      dataIndex: 'terminalId',
      key: 'terminalId',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (_, { status, transactionId }) => (
        <Tag color={statusStringToColour(status)} key={'status-' + transactionId}>
          {status.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      render: (_, { type, transactionId }) => (
        <Tag color={typeToColour(type)} key={'type-' + transactionId}>
          {type.toUpperCase()}
        </Tag>
      ),
    },
  ];

  return (
    <Card className="flex-1" height="unlimited" width="unlimited">
      <Table columns={columns} dataSource={lastFiftyTransactions.data.transactions} />
    </Card>
  );
}

export default Home;
