import Sidenav from '@components/organisms/Sidenav';
import Topbar from '@components/organisms/Topbar';
import Home from '@pages/Home';
import Integration from '@pages/Integration';
import Index from '@pages/Login';
import Transactions from '@pages/Transactions';
import React from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';

import { HOME_ROUTE, INTEGRATION_ROUTE, LOGIN_ROUTE, TRANSACTIONS_ROUTE } from './routes';

const Router: React.FC = () => (
  <BrowserRouter>
    <Routes>
      <Route path={LOGIN_ROUTE()} element={<Index />} />
      <Route
        path="/"
        element={
          <>
            <Sidenav />
            <div className="content">
              <Topbar />
              <div className="outlet">
                <Outlet />
              </div>
            </div>
          </>
        }
      >
        <Route path={HOME_ROUTE()} element={<Home />} />
        <Route path={TRANSACTIONS_ROUTE()} element={<Transactions />} />
        <Route path={INTEGRATION_ROUTE()} element={<Integration />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default Router;
