import type { Transaction } from '@services/TransactionsService';

export const serializeTransactionsForCsv = (transactions: Transaction[]) =>
  transactions.map(transaction => ({
    id: transaction._id,
    terminalId: transaction.terminalId,
    date: transaction.date,
    amount: transaction.amount,
    status: transaction.status,
    type: transaction.type,
  }));
