import useAsync from '@hooks/useAsync';
import { TransactionsService } from '@services/TransactionsService';
import { DATE_FORMAT, formatDate } from '@utils/date';

const useGetLastMonthSuccessFulTransactions = () =>
  useAsync(async () => {
    const successFulTransactions = await TransactionsService.getLastMonthSuccessFulTransactions();
    const totalByDate = successFulTransactions
      .map(transaction => ({
        ...transaction,
        paymentDatetime: formatDate(new Date(transaction.date), DATE_FORMAT.DATE),
      }))
      .sort((a, b) => (a.paymentDatetime < b.paymentDatetime ? -1 : 1))
      .reduce<Record<string, number>>((acc, next) => {
        if (!acc[next.paymentDatetime]) acc[next.paymentDatetime] = 0;
        acc[next.paymentDatetime] += Number(next.amount);
        return acc;
      }, {});
    const total = Object.values(totalByDate).reduce<number>((acc, next) => acc + next, 0);
    return {
      transactions: successFulTransactions,
      totalByDate,
      total,
    };
  });

export default useGetLastMonthSuccessFulTransactions;
