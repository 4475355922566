import useAsync from '@hooks/useAsync';
import { TransactionsService } from '@services/TransactionsService';
import moment from 'moment';

const useGetLastFiftyTransactionsAsync = () =>
  useAsync(async () => {
    const transactionsResult = await TransactionsService.getTransactions({
      from: moment.utc().subtract(6, 'month').toDate(),
      to: moment.utc().toDate(),
      page: 1,
    });

    return {
      transactions: transactionsResult,
    };
  });

export default useGetLastFiftyTransactionsAsync;
