import { HOME_ROUTE, TRANSACTIONS_ROUTE } from '@routing/routes';
import { FC } from 'react';
import { Link } from 'react-router-dom';

const Integration: FC = () => (
  <div id="integration-page">
    integration
    <ul>
      <li>
        <Link to={HOME_ROUTE()}>home</Link>
      </li>
      <li>
        <Link to={TRANSACTIONS_ROUTE()}>transactions</Link>
      </li>
    </ul>
  </div>
);

export default Integration;
