import { AsyncData } from '@hooks/useAsync';
import type { Transaction } from '@services/TransactionsService';

import useGetLastFiftyTransactionsAsync from './useGetLastFiftyTransactionsAsync';
import useGetLastMonthSuccessFulTransactions from './useGetLastMonthSuccessFulTransactions';

type LastMonthSuccessfulTransactionsAsync = {
  transactions: Transaction[];
  totalByDate: Record<string, number>;
  total: number;
};

type LastFiftyTransactionsAsync = {
  transactions: Transaction[];
};

export type HomeContext = {
  lastMonthSuccessfulTransactionsAsync: AsyncData<LastMonthSuccessfulTransactionsAsync>;
  lastFiftyTransactionsAsync: AsyncData<LastFiftyTransactionsAsync>;
};

const useGetHomeContext = (): HomeContext => {
  const lastMonthSuccessfulTransactionsAsync = useGetLastMonthSuccessFulTransactions();
  const lastFiftyTransactionsAsync = useGetLastFiftyTransactionsAsync();

  return {
    lastMonthSuccessfulTransactionsAsync,
    lastFiftyTransactionsAsync,
  };
};

export default useGetHomeContext;
