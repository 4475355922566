import LoginForm from '@components/organisms/LoginForm';
import { FC } from 'react';

import './index.scss';

const Index: FC = () => (
  <div id="login-page">
    <div className="login-container">
      <h2>
        <b>Welcome to your Paynah dashboard</b>
      </h2>
      <br />
      <br />
      <LoginForm />
    </div>
  </div>
);

export default Index;
