import Flex from '@components/atoms/Flex';
import { AuthenticationService } from '@services/AuthenticationService';
import { Button } from 'antd';
import { FC } from 'react';

import './index.scss';

const TopBar: FC = () => (
  <Flex className="topbar px-4" justify="end" align="center">
    <Button onClick={() => AuthenticationService.logout()}>Logout</Button>
  </Flex>
);

export default TopBar;
