import { FC, HTMLAttributes, ReactElement, ReactNode } from 'react';
import cn from 'classnames';

import './index.scss';

export interface ICardProps extends HTMLAttributes<HTMLDivElement> {
  width?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'unlimited';
  height?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'unlimited';
  children?: ReactNode;
}

const Card: FC<ICardProps> = ({ children, width = 'm', height = 'm', className, ...props }) => (
  <div className={cn('card', `card-width-${width}`, `card-height-${height}`, className)} {...props}>
    {children}
  </div>
);

export default Card;
